import { alpha, Components, createTheme, darken, lighten, Palette, Theme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

// augment theme types with our custom variables
declare module '@mui/material/styles' {
    interface Theme {
        colors: typeof colors,
        cwc: typeof cwcHelpers,
    }

    interface ThemeOptions {
        colors?: typeof colors,
    }

    interface PaletteColor {
        tint?: string;
        tint1?: string;
        alpha?: string;
        alpha1?: string;
        light1?: string;
    }

    interface Palette {
        tertiary: PaletteColor;
        neutral: PaletteColor;
        lightBorder: PaletteColor;
        lightBg: PaletteColor;
        alphaBg: PaletteColor;
        neutralIcon: PaletteColor;
        delete: PaletteColor;
    }

    interface TypographyVariants {
        captionLight: React.CSSProperties;
        fieldLabel: React.CSSProperties;
        bodyLight: React.CSSProperties;
      }
    
      // allow configuration using `createTheme`
      interface TypographyVariantsOptions {
        captionLight?: React.CSSProperties;
        bodyLight?: React.CSSProperties;
        fieldLabel?: React.CSSProperties;
      }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      captionLight: true;
      bodyLight: true;
      fieldLabel: true;
    }
  }

declare module '@mui/material' {
    interface RadioPropsColorOverrides {
        tertiary: true;
        neutral: true;
    }

    interface CheckboxPropsColorOverrides {
        tertiary: true;
        neutral: true;
    }
    
    interface ButtonPropsColorOverrides {
        tertiary: true;
        neutral: true;
        lightBg: true,
        alphaBg: true,
        neutralIcon: true;
    }
    
    interface ButtonGroupPropsColorOverrides {
        tertiary: true;
        neutral: true;
        lightBg: true,
        alphaBg: true,
        neutralIcon: true;
    }

    interface TextFieldPropsColorOverrides {
        tertiary: true;
        neutral: true;
        lightBg: true,
        alphaBg: true,
        neutralIcon: true;
    }

    interface IconButtonPropsColorOverrides {
        tertiary: true;
        neutralIcon: true;
    }

    interface SvgIconPropsColorOverrides {
        tertiary: true;
        neutralIcon: true;
        neutral: true;
    }

    interface ToggleButtonGroupPropsColorOverrides {
        tertiary: true;
    }
}

export const ZINDEX_POPPER = 4000;

// TODO: fill in colors and palette when UI design has been confirmed
const colors = {
    // cyan
    primary: {
        10: '#002020',
        20: '#003737',
        30: '#004F4F',
        40: '#006A6A',
        50: '#008585',
        55: '#00D2D2',
        60: '#00A1A1',
        70: '#00BEBE',
        80: '#26DCDC',
        90: '#56F9F8',
        95: '#ADFFFE',
        99: '#F1FFFE',
    },
    // teal
    secondary: {
        10: '#002019',
        20: '#00382D',
        30: '#005142',
        40: '#006B58',
        50: '#008770',
        55: '#59E8C7',
        60: '#00A388',
        70: '#1EC0A1',
        80: '#4BDDBC',
        90: '#6DFAD8',
        95: '#B8FFEA',
        99: '#F3FFF9',
    },
    // blue
    tertiary: {
        10: '#001D36',
        20: '#003258',
        30: '#00497D',
        40: '#0361a3',
        50: '#337ABE',
        55: '#256199',
        60: '#5194DA',
        70: '#6EAFF7',
        80: '#9FCAFF',
        90: '#D1E4FF',
        95: '#EAF1FF',
        99: '#FDFCFF',
    },
    // red
    error: {
        10: '#410002',
        20: '#690005',
        30: '#93000A',
        40: '#BA1A1A',
        50: '#DE3730',
        60: '#FF5449',
        70: '#FF897D',
        80: '#FFB4AB',
        90: '#FFDAD6',
        95: '#FFEDEA',
        99: '#FFFBFF',
    },
    // white, gray, black
    neutral: {
        0: '#000000',
        // default icon color on white background
        20: '#6F7979',
        // caption text color
        30: '#878787',
        // border color for tags, table, and outline buttons
        50: '#DCDCDC',
        95: '#EFF1F0',
        // background color for page base layout
        98: '#F7FAF9',
        100: '#FFFFFF',
    },
    // random one off colors
    extras: {
        // data table header color
        navy: '#162C44',
    },
};

const palette: Palette = {
    primary: {
        light: colors.primary[90],
        main: colors.primary[55],
        dark: colors.primary[40],
    },
    secondary: {
        light: colors.secondary[90],
        main: colors.secondary[55],
        dark: colors.secondary[40],
    },
    tertiary: {
        contrastText: colors.neutral[100],
        light: colors.tertiary[90],
        main: colors.tertiary[55],
        dark: colors.tertiary[30],
        tint: lighten(colors.tertiary[40], 0.92),
        tint1: lighten(colors.tertiary[40], 0.84),
    },
    neutral: {
        light: colors.neutral[98],
        light1: colors.neutral[95],
        main: colors.neutral[50],
        dark: colors.neutral[30],
    },
    // light border colours
    lightBorder: {
        light: 'N/A',
        contrastText: 'N/A',
        main: alpha(colors.neutral[0], 0.12),
        dark: alpha(colors.neutral[0], 0.24),
    },
    // light background for buttons
    lightBg: {
        light: 'N/A',
        contrastText: 'N/A',
        main: colors.neutral[100],
        dark: darken(colors.neutral[100], 0.04),
    },
    // transparent background for buttons
    // useful for inheriting parent's background color but still providing hover effect
    alphaBg: {
        light: 'N/A',
        contrastText: 'N/A',
        main: 'rgba(0, 0, 0, 0)',
        dark: 'rgba(0, 0, 0, 0.04)',
    },
    // default neutral icon color on white background
    neutralIcon: {
        light: lighten(colors.neutral[20], 0.92),
        contrastText: 'N/A',
        main: colors.neutral[20],
        dark: darken(colors.neutral[20], 0.08),
    },
    // Todo: fix contrastText
    error: {
        main: colors.error[40],
    },
    // Delete icon
    delete: {
        light: lighten(colors.error[60], 0.92),
        contrastText: 'N/A',
        main: colors.error[60],
        dark: darken(colors.error[60], 0.08),
    },
};

function createComponents(theme: Theme): Components {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                },
                outlined: {
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                color: 'tertiary.main',
            },
        },
    };
}

function createTypography(theme: Theme): TypographyOptions {
    return {
        // light version of body (e.g. for placeholder text)
        bodyLight: {
            ...theme.typography.body1,
            color: colors.neutral[30],
        },
        // light version of caption
        captionLight: {
            ...theme.typography.caption,
            color: colors.neutral[30],
        },
        // Subdued label for a data field
        fieldLabel: {
            ...theme.typography.caption,
            color: colors.neutral[30],
            fontWeight: 500,
            textTransform: 'uppercase',
        },
    }
}

// Helpers for common CWC UI elements
function createCwcHelpers(theme: Theme) {
    return {
        // default border for lots of elements (e.g. tables, dividers)
        border: (color?: string) => `2px solid ${color ?? theme.palette.neutral.main}`,
    };
}

const baseTheme = createTheme({
    colors: colors,
    palette: palette,
});

const cwcHelpers = createCwcHelpers(baseTheme);

const theme = createTheme(baseTheme, {
    components: createComponents(baseTheme),
    typography: createTypography(baseTheme),
    // custom helper functions
    cwc: cwcHelpers,
});

export function getTheme(mode = 'light') {
    return theme;
}

// Helper function to fetch a palette color or use it as a CSS color property if it doesn't exist in the palette
export function resolveColor(t: Theme, color: string) {
    const resolvedColor = _.get(t.palette, color);
    if (typeof resolvedColor === 'string') {
        return resolvedColor;
    } else if (typeof resolvedColor === 'object') {
        return resolvedColor.main;
    }

    return color;
}